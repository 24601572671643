.history-results {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0px 20px;

    >span {
        margin-left: 5px;
    }
}

.vela-results {
    margin: 4px;
    text-align: center;
    color: white;
    cursor: pointer;

    &:hover {
        opacity: .8;
    }
}

.odd-method-select {
    margin-right: 10px;
}

.card-table {
    height: 400px;
    overflow: auto!important;
}

.aviator-content {
    position: relative;
    margin-bottom: 16px;

    .loading-puff {
        position: absolute;
        top: 82px;
        left: 100px;
    }

    .odd-aviator {
        position: absolute;
        top: 91px;
        left: 82px;
        font-weight: bold;
    }
}

.bg-blue {
    background-color: #1d8cf8 !important;
}

.bg-info {
   background-color: #1d8cf8 !important;
}

.swal2-dark {
    background-color: #525f7f;
    color: #fff;
}

.swal2-dark .swal2-popup {
    background-color: #3e3e3e;
    color: #fff;
    border: 1px solid #444;
}

.swal2-dark .swal2-title {
    color: #fff;
}

.swal2-dark .swal2-content {
    color: #fff;
}

.swal2-dark .swal2-input {
    background-color: #555;
    color: #fff;
    border: 1px solid #666;
}

.swal2-dark .swal2-confirm {
    background-color: #4caf50;
    color: #fff;
}

.swal2-dark .swal2-cancel {
    background-color: #f44336;
    color: #fff;
}

.swal2-dark .swal2-styled:focus {
    box-shadow: 0 0 2px 2px rgba(255, 255, 255, 0.5);
}

.nav-balance {
    justify-content: flex-end;
    width: 100%;

    @media (max-width: 500px) {
        margin-top: -34px;
    }
}


.history-hours {
    .body-hours {
        height: 380px;
        overflow-x: auto;

        @media (max-width: 576px) {
            height: 280px;
        }
    }

    .hour {
        &-item {
            width: 100%;
            border: 1px solid $default;
            border-radius: 8px;
            padding: 4px;
            text-align: center;
        }

        &-count {
            width: 100%;
            border: 1px solid $primary;
            background-color: $primary;
            border-radius: 8px;
            font-weight: 600 !important;
            padding: 4px;
            text-align: center;
            margin-left: 12px;
        }
    }
}